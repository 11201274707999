<template>
  <v-main>
    <v-container>
      <v-row dense>
        <v-col class="col-md-6 offset-md-3">
          <div class="d-flex justify-space-between">
            <BackLink />
            <h2
              class="text-h5 grey--text text-capitalize text--darken-2"
              style="lineheight: 1.7"
            >
              List
              {{ $store.state.settings.defaultData }}
            </h2>
          </div>

          <modal-trash-box
            :is-active="isModalActive"
            :trash-subject="trashObjectName"
            @confirm="trashConfirm"
            @cancel="trashCancel"
          />
          <v-text-field
            ref="search"
            v-model="search.q"
            @keydown.enter="
              paginated = false;
              onSearch();
            "
            dense
            solo
            flat
            hide-details
            :placeholder="`CARI ${
              $store.state.settings.defaultData &&
              $store.state.settings.defaultData.toUpperCase()
            }`"
            background-color="#efefef"
            color="#00cccc"
            class="px-0 mb-1"
          >
            <!-- <template v-slot:prepend-inner>
              <v-btn
                small
                class="ml-n2 ma-1 grey"
                icon
                tile
                @click.stop="importData"
              >
                <v-icon small color="white">mdi-file-import</v-icon>
              </v-btn>
            </template> -->
            <template v-slot:prepend-inner>
              <v-btn
                class="ml-n2 ma-1"
                :class="select ? 'primary' : 'grey'"
                icon
                tile
                small
                @click="select = !select"
              >
                <v-icon small color="white">mdi-check-bold</v-icon>
              </v-btn>
              <v-btn
                v-if="$store.state.settings.multi_print"
                small
                tile
                icon
                class="ma-1 grey"
                title="Print"
                @click="doPrint"
              >
                <v-icon small color="white">mdi-printer-wireless</v-icon>
              </v-btn>
              <v-btn
                v-if="$store.state.settings.exporter"
                small
                tile
                icon
                class="ma-1 grey"
                title="Print"
                @click="exportData"
              >
                <v-icon small color="white">mdi-table-arrow-down</v-icon>
              </v-btn>
            </template>
            <template v-slot:append>
              <v-icon
                v-if="
                  Object.keys(search).length !== 0 &&
                  search.constructor === Object
                "
                title="Hapus Filter"
                color="primary"
                @click="clearSearch()"
                >mdi-close-circle</v-icon
              >
              <v-btn small icon tile>
                <v-avatar tile size="17">
                  <v-img
                    max-height="17"
                    max-width="17"
                    src="@/assets/images/scan_icon.png"
                    @click.stop="onScan(search.q)"
                  />
                </v-avatar>
              </v-btn>
            </template>
          </v-text-field>
          <FilterButton
            @doFilter="(v) => doFilter(v)"
            @clearFilter="clearSearch"
            @exportData="(v) => exportGizi(v)"
            @printData="(v) => printData(v)"
            :active="filter"
          />
          <template v-if="isLoading">
            <v-skeleton-loader
              v-for="x in 3"
              :key="x"
              class="mx-auto mb-1"
              height="60px"
              tile
              type="list-item-two-line"
            ></v-skeleton-loader>
          </template>
          <template v-else>
            <section class="section" v-if="!data.length">
              <div class="content has-text-grey has-text-centered">
                <template>
                  <p>DATA BELUM ADA</p>
                </template>
              </div>
            </section>
            <template v-else>
              <div
                v-if="$store.state.settings.gizi"
                class="text-subtitle-1 pa-1"
              >
                Total Pasien: {{ total }}
              </div>
              <div class="d-flex" v-for="(item, index) in data" :key="item._id">
                <v-checkbox
                  v-if="select"
                  color="primary"
                  v-model="multiSelect"
                  dense
                  hide-details
                  class="align-center pa-0 ma-0"
                  :ripple="false"
                  :value="item._id"
                ></v-checkbox>
                <v-card
                  elevation="1"
                  class="rounded-r-lg text-uppercase white mb-2"
                  width="100%"
                >
                  <v-list-group
                    color="black"
                    class="rounded-lg"
                    no-action
                    append-icon=""
                  >
                    <template v-slot:activator>
                      <div
                        v-if="$store.state.settings.gizi"
                        class="text-h5 pa-1"
                      >
                        {{ index + 1 }}.
                      </div>
                      <v-container class="pa-2 grey--text text--darken-3">
                        <div class="d-flex align-center mb-1">
                          <v-chip
                            v-if="
                              $store.state.settings.defaultData === 'pasien' &&
                              item.no_rm
                            "
                            label
                            x-small
                            class="mr-1 px-1 text-normal grey darken-1 white--text"
                          >
                            NO RM
                          </v-chip>
                          <div
                            class="nexa-black mt-1"
                            style="font-size: 1em; lineheight: 1.5"
                          >
                            {{
                              item.no_rm ||
                              item.content.id_staff ||
                              item.content.kode
                            }}
                          </div>
                          <v-btn
                            outlined
                            x-small
                            color="grey darken-1"
                            class="ml-auto nexa-black mt-0 text-capitalize"
                          >
                            {{
                              formatDate(item.updated_at, "DD MMM YYYY - H:mm")
                            }}
                          </v-btn>
                        </div>
                        <div
                          class="text-capitalize mb-1"
                          style="line-height: 1; font-size: 1em"
                        >
                          {{ item.nama }}
                        </div>
                        <div
                          class="text-caption mb-1 grey--text"
                          style="line-height: 1"
                        >
                          {{
                            item.content.poli ||
                            item.content.ruangan ||
                            item.content.unit ||
                            item.content.klinik ||
                            item.content.lokasi ||
                            "-"
                          }}
                        </div>
                        <v-chip
                          v-if="
                            item.content.keterangan ||
                            item.content.status ||
                            item.content.kelas
                          "
                          x-small
                          outlined
                          color="primary"
                          class="ml-auto"
                        >
                          {{
                            item.content.keterangan ||
                            item.content.status ||
                            item.content.kelas
                          }}
                        </v-chip>
                      </v-container>
                    </template>
                    <v-card-text>
                      <v-row
                        v-for="(itemx, i) in form"
                        :key="i"
                        no-gutters
                        class="align-center text-uppercase"
                      >
                        <template
                          v-if="
                            !(
                              itemx.type === 'upload' ||
                              itemx.type === 'custom' ||
                              itemx.type === 'popup'
                            )
                          "
                        >
                          <v-col cols="12" class="pa-0">
                            <v-divider class="col-12 my-0 pa-0"></v-divider>
                          </v-col>
                          <v-col
                            cols="4"
                            class="pt-1 pb-0"
                            style="font-size: 8pt"
                          >
                            {{ itemx.label }}
                          </v-col>
                          <v-col cols="8" class="pt-1 pb-0 text-right">
                            {{
                              (itemx.type === "date"
                                ? formatDate(
                                    item.content[itemx.model],
                                    "D MMM YYYY"
                                  )
                                : item[itemx.model] ||
                                  item.content[itemx.model]) || "-"
                            }}
                          </v-col>
                        </template>
                      </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-space-between">
                      <template>
                        <v-btn
                          depressed
                          small
                          outlined
                          class="grey--text col"
                          @click.prevent="trashModal(item)"
                          v-text="'Hapus'"
                        />
                        <v-btn
                          depressed
                          small
                          light
                          color="#00cccc"
                          class="white--text col"
                          :to="{ name: 'form.edit', params: { id: item._id } }"
                          v-text="'Edit'"
                        />
                        <v-btn
                          v-if="$store.state.settings.gizi"
                          depressed
                          small
                          color="error"
                          class="white--text col"
                          @click.prevent="statusUpdate(item._id)"
                          v-text="'Pasien Pulang'"
                        />
                      </template>
                    </v-card-actions>
                  </v-list-group>
                </v-card>
              </div>
            </template>
          </template>
          <!--Add here the vuetify directive -->
          <template>
            <div style="width: 100%" class="text-center" v-if="loadMore">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-else class="text-center">
              <v-btn
                v-if="paginated && !isLoading"
                depressed
                small
                light
                outlined
                color="#00cccc"
                class="my-2"
                @click="infiniteScrolling()"
                v-text="'Load More'"
              />
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <a href="#/form" style="position: fixed; top: 60%; z-index: 99; right: 0">
      <v-img src="@/assets/images/plus-side.png" max-width="20"></v-img>
    </a>
    <confirm-dialog ref="ConfirmDialog" :confirm="confirmButton">
      <span v-text="confirmText" />
    </confirm-dialog>

    <modal-print ref="modalPrint" :type="type" />
    <!-- <modal-import ref="modalImport" title="Import Data" /> -->
    <scanner ref="scanner" />
  </v-main>
</template>

<script>
import ModalTrashBox from "@/components/ModalTrashBox";
import ConfirmDialog from "@/components/ConfirmDialog";
import ModalPrint from "@/components/MultiPrint";
import Scanner from "@/components/Scanner";

export default {
  name: "Index",
  components: {
    ModalTrashBox,
    ConfirmDialog,
    ModalPrint,
    Scanner,
    // ModalImport: () => import("@/components/ModalImport"),
    FilterButton: () => import("@/components/Filter"),
  },
  data() {
    return {
      isModalActive: false,
      trashObject: null,
      data: [],
      search: {},
      isLoading: null,
      dataUrl: "/pasien",
      loadMore: false,
      total: 0,
      paginated: false,
      page: 1,
      perPage: 15,
      filter: false,
      filterData: this.filterObject(),
      confirmText: null,
      confirmButton: null,
      select: false,
      multiSelect: [],
    };
  },
  computed: {
    type() {
      if (
        this.$store.state.settings.resep.enable === "enable" ||
        this.$store.state.settings.gizi
      ) {
        return "resep";
      } else if (this.$store.state.settings.defaultData === "inventaris") {
        return "inventaris";
      } else if (this.$store.state.settings.defaultData === "staff") {
        return "staff";
      } else {
        if (
          this.data.kelamin &&
          this.data.kelamin.toLowerCase() === "perempuan"
        ) {
          return "gelangpink";
        } else {
          return "gelangbiru";
        }
      }
    },
    trashObjectName() {
      if (this.trashObject) {
        return this.trashObject.nama;
      }

      return null;
    },
    form() {
      return this.$store.state.settings.form;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData(page) {
      let pageLoad = parseInt(page) || 1;
      let skip = parseInt(page) > 1 ? (parseInt(page) - 1) * 15 : 0;
      this.data = page ? this.data : [];
      this.isLoading = page ? false : true;
      if (this.dataUrl) {
        let url = `${this.dataUrl}?deleted[$ne]=true&content.type[$ne]=inventaris&$skip=${skip}&$limit=15&$sort[updated_at]=-1`;
        if (this.$store.state.settings.defaultData === "inventaris") {
          url = `${this.dataUrl}?deleted[$ne]=true&content.type=inventaris&$skip=${skip}&$limit=15&$sort[updated_at]=-1`;
        }
        setTimeout(() => {
          this.axios
            .get(url)
            .then((r) => {
              r.data.data.forEach((item) => {
                this.data.push(item);
              });
              this.paginated = r.data.data.length >= 15 ? true : false;
              this.page = pageLoad;
              this.total = r.data.total;
              console.log(this.paginated, this.page);
            })
            .catch((err) => {
              this.data = [];
              console.log(err.message);
            })
            .finally(() => {
              this.isLoading = false;
              this.loadMore = false;
            });
        }, 500);
      }
    },
    infiniteScrolling() {
      this.loadMore = true;
      console.log(this.paginated, this.page);
      if (!this.paginated) {
        return;
      }
      setTimeout(() => {
        if (this.search.q) {
          this.onSearch(this.page + 1);
        } else if (this.filter) {
          this.doFilter(null, this.page + 1);
        } else {
          this.getData(this.page + 1);
        }
      }, 500);
    },
    onSearch(page) {
      this.data = page ? this.data : [];
      let pageLoad = parseInt(page) || 1;
      let skip = parseInt(page) > 1 ? (parseInt(page) - 1) * 15 : 0;
      this.isLoading = page ? false : true;
      //console.log(this.search)
      setTimeout(() => {
        // Lazily load input items
        let filter = [
          {
            trigger: "no_rm",
            value: this.search.q,
          },
          {
            trigger: "nama",
            value: this.search.q,
          },
          {
            trigger: "content.kode",
            value: this.search.q,
          },
        ];

        this.filterData.search = filter;
        this.filterData.skip = skip;

        this.axios
          .post(`${window.rawUrl}/search`, this.filterData)
          .then((r) => {
            r.data.forEach((item) => {
              this.data.push(item);
            });
            this.paginated = r.data.length >= 15 ? true : false;
            this.page = pageLoad;
            this.total = r.data.total;
            console.log(this.paginated, this.page);
          })
          .catch((err) => {
            this.data = [];
            console.log(err.message);
          })
          .finally(() => {
            this.isLoading = false;
            this.loadMore = false;
          });
      }, 500);
    },
    trashModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    trashConfirm() {
      this.isModalActive = false;
      this.axios
        .delete(`${this.dataUrl}/${this.trashObject._id}`)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    trashCancel() {
      this.isModalActive = false;
    },
    clearSearch() {
      this.loadMore = false;
      this.paginated = false;
      this.filter = false;
      this.page = 1;
      this.data = [];
      this.search = {};
      this.filterData = this.filterObject();
      this.getData();
    },
    filterObject() {
      let filter = {
        trigger: "content.type",
        value: "inventaris",
        skip: true,
      };

      if (this.$store.state.settings.defaultData === "inventaris") {
        filter.skip = false;
      }

      return {
        filter: [filter],
        search: [],
        tanggal: 0,
        skip: 0,
        limit: 15,
      };
    },
    doFilter(v, page) {
      this.data = page ? this.data : [];
      let pageLoad = parseInt(page) || 1;
      let skip = parseInt(page) > 1 ? (parseInt(page) - 1) * 15 : 0;
      this.isLoading = page ? false : true;

      this.filter = true;
      if (v) {
        let filter = v.filter.map((item) => {
          return {
            trigger: `content.${v.trigger}`,
            value: item,
          };
        });
        // console.log(filter);
        this.filterData.filter = this.filterObject().filter.concat(filter);
        this.filterData.tanggal = parseInt(v.tanggal);
      }
      this.filterData.skip = skip;

      if (this.dataUrl) {
        setTimeout(() => {
          if (this.filterData.tanggal !== 0 || this.filterData.filter.length) {
            this.axios
              .post(`${window.rawUrl}/search`, this.filterData)
              .then((r) => {
                r.data.forEach((item) => {
                  this.data.push(item);
                });
                this.paginated = r.data.length >= 15 ? true : false;
                this.page = pageLoad;
                this.total = r.data.total;
                console.log(this.paginated, this.page);
              })
              .catch((err) => {
                this.data = [];
                console.log(err.message);
              })
              .finally(() => {
                this.isLoading = false;
                this.loadMore = false;
              });
          } else {
            this.clearSearch();
          }
        }, 500);
      }
    },
    exportGizi(custom) {
      let url = `${window.rawUrl}/export`;
      let data = this.filterData;
      Object.assign(data, { form: this.form });

      if (this.$store.state.settings.gizi) {
        url = `${window.rawUrl}/export-gizi`;
        if (custom) {
          url = `${window.rawUrl}/custom-export`;
          Object.assign(data, {
            form: this.form,
            waktu: custom,
            data: {
              menu: this.$store.state.settings.menu || [],
              diet: this.$store.state.settings.diet || [],
            },
          });
        }
      }
      setTimeout(() => {
        this.axios
          .post(url, data)
          .then((r) => {
            console.log(r.data);
            window.location.href =
              window.rawUrl + "/assets/uploads/files/" + r.data.path;
          })
          .catch((err) => {
            console.log(err.message);
          });
      }, 500);
    },
    exportData() {
      if (this.$store.state.settings.gizi) {
        this.exportGizi();
      } else {
        let url = `${window.rawUrl}/export`;
        let data = this.filterData;
        Object.assign(data, { form: this.form });

        setTimeout(() => {
          this.axios
            .post(url, data)
            .then((r) => {
              console.log(r.data);
              window.location.href =
                window.rawUrl + "/assets/uploads/files/" + r.data.path;
            })
            .catch((err) => {
              console.log(err.message);
            });
        }, 500);
      }
    },
    printData(val) {
      this.confirmText = "Apakah anda yakin melakukan pencetakan masal?";
      this.confirmButton = "Ya";
      this.$refs.ConfirmDialog.open()
        .then(() => {
          let url = `${window.rawUrl}/multi-print`;
          let data = {
            filter: this.filterData.filter,
            tanggal: this.filterData.tanggal,
            type: val.type,
            waktu: val.waktu,
            snack: val.snack,
          };
          if (this.multiSelect.length) {
            data.pasien = this.multiSelect;
          }
          setTimeout(() => {
            this.axios
              .post(url, data)
              .then((r) => {
                console.log(r.data);
              })
              .catch((err) => {
                console.log(err.message);
              });
          }, 500);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    statusUpdate(id) {
      this.confirmText = "Apakah anda yakin mengubah status pasien ini?";
      this.confirmButton = "Ya";
      this.$refs.ConfirmDialog.open()
        .then(() => {
          let url = `${this.dataUrl}/${id}`;
          setTimeout(() => {
            this.axios
              .patch(url, {
                deleted: "true",
              })
              .then((r) => {
                console.log(r.data);
                this.$root.snackbar.show({
                  text: "Status berhasil diupdate",
                });
                this.data = this.data.filter((item) => item._id !== id);
              })
              .catch((err) => {
                console.log(err.message);
              });
          }, 500);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    doPrint() {
      this.$refs.modalPrint
        .open()
        .then((res) => {
          console.log(res);
          res.forEach((item) => {
            let url = `${window.rawUrl}/multi-print`;
            let data = {
              filter: this.filterData.filter,
              tanggal: this.filterData.tanggal,
              printer: item.printer,
              copies: item.settings.copies,
            };
            if (this.$store.state.settings.gizi) {
              data.gizi = this.$store.state.settings.gizi;
              data.obat = item.obat;
              data.date = item.date;
            }
            if (this.multiSelect.length) {
              data.pasien = this.multiSelect;
            }
            setTimeout(() => {
              this.axios
                .post(url, data)
                .then((r) => {
                  console.log(r.data);
                })
                .catch((err) => {
                  console.log(err.message);
                });
            }, 500);
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },
    onScan() {
      this.$refs.scanner
        .open()
        .then((val) => {
          this.search = { q: val };
          this.onSearch();
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
  watch: {
    select(v) {
      if (!v) this.multiSelect = [];
    },
  },
};
</script>
