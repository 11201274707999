<template>
  <v-dialog v-model="dialog" scrollable max-width="500" @keydown.esc="cancel()" @click:outside="cancel()">
    <v-card tile class="white">
      <v-card-title class="h5 justify-space-between">
        {{title}}

        <v-switch class="mt-0" color="#00cccc" name="enable" dense inset v-model="allEnable" @change="changeAll(allEnable)"></v-switch>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-menu
          v-if="$store.state.settings.gizi"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">            
          <v-btn
            color="grey"
            small
            outlined
            class="mb-2 white--text"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              left
              dark
            >
              mdi-calendar
            </v-icon>
            Tanggal Penyajian: {{ formatDate(date) }}
          </v-btn>

          </template>
          <v-date-picker
            v-model="date"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
        <template v-for="(item, y) in printData">
          <v-container v-if="item.content.page.type === type || item.content.page.type === 'pasien'" :key="y">
            <v-row class="berkas align-center grey lighten-3 mb-3">
              <v-col cols="3" sm="2">
                <v-text-field
                  type="number"
                  name="jumlah"
                  label="JML"
                  class="text-center"
                  outlined
                  :value="item.content.page.jumlah"
                  dense
                  flat
                  hide-details="auto"
                ></v-text-field>
                <v-text-field
                  style="display:none;"
                  type="hidden"
                  name="enablex"
                  :value="y"
                ></v-text-field>
                <v-text-field
                  style="display:none;"
                  type="hidden"
                  name="selectedPrinter"
                  :value="item.content.page.printer"
                ></v-text-field>
                <v-text-field
                  style="display:none;"
                  type="hidden"
                  name="printerId"
                  :value="item._id"
                ></v-text-field>
                <v-text-field
                  style="display:none;"
                  type="hidden"
                  name="obat"
                  :value="item.obat"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="8" class="py-2 text-uppercase">
                {{item.obat}}<br>
                <span class="is-hidden-mobile text-normal nama-label">{{`${item.name} (${item.content.page.printer})`}}</span>
              </v-col>
              <v-col cols="3" sm="2" class="justify-end">
                <v-switch class="mt-0" color="#00cccc" name="enable" dense inset :value="enable[y]" :input-value="enable[y]" @change="change(y)"></v-switch>
              </v-col>
              <!-- <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions class="text-body-1 px-3">
                    <div class="col-10 pa-0">Preview</div>
                    <v-avatar
                      size="20"
                      tile
                      class="col-2 pa-0 justify-end"
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-avatar>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="pa-2 mb-2" style="background:#fff;margin: auto;border:1px solid #000;border-radius:5px;width:fit-content">
                      <iframe frameBorder="0" class="embed-responsive-item" :src="url + '/label/' + item._id + '/' + pasien" scrolling="no" :style="`
                      width: ${item.content.page.width}mm;
                      height: ${item.content.page.height}mm;`"/>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels> -->
            </v-row>
          </v-container>
        </template>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          depressed
          small
          :loading="isLoading"
          color="#00cccc"
          class="white--text col"
          @click="accept"
        >
          Print
        </v-btn>
        <v-btn
          v-if="unit !== 'steril'"
          depressed
          small
          :loading="isLoading"
          color="grey"
          class="white--text col"
          @click="cancel"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from '@/components/InputField'
import PrintData from '@/components/PrintData'
export default {
  name: 'MultiPrint',
  components: { InputField, PrintData },
  props: {
      title: { type: String, default: 'CETAK ETIKET' },
      type: String,
      unit: String,
  },
  data () {
    return {
      qty: 1,
      isLoading: false,
      dialog: false,
      id: null,
      resolve : null,
      reject : null,
      label: [],
      enable: [],
      allEnable: true,
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      url: this.$store.state.settings.server_url || "http://localhost:3030",
      printUrl: (this.$store.state.settings.print_server ? "http://localhost:3030" : this.$store.state.settings.server_url) || "http://localhost:3030",
    }
  },
  computed: {
    printData() {
      let data = this.label;
      if(this.custom) {
        data = data.filter(x => x.name.toLowerCase() === this.$store.state.settings.custom.label.toLowerCase());
      } else {
        data = data.filter(x => {
          if(this.$store.state.settings.custom.enable) {
            return x.name.toLowerCase() !== this.$store.state.settings.custom.label.toLowerCase();
          } else {
            return this.label;
          }
        })
      }
      return data;
    },
    giziObject() {
      return [
        {
          obat: "Makan Pagi",
          tipe: "etiket_putih",
        },
        {
          obat: "Makan Siang",
          tipe: "etiket_putih",
        },
        {
          obat: "Makan Sore",
          tipe: "etiket_putih",
        },
        {
          obat: "Snack Pagi",
          tipe: "etiket_biru",
        },
        {
          obat: "Snack Sore",
          tipe: "etiket_biru",
        },
      ];
    }
  },
  methods: {
    open(){
      this.allEnable = true;
      this.date = new Date().toISOString().substr(0, 10);
      this.getData()
      this.enableAll()
      return new Promise((resolve, reject)=>{
        this.resolve = resolve
        this.reject = reject
        this.dialog = true
      })
    },
    change() {
      this.$refs.printData.changeAll(this.enable)
    },
    accept() {
      let results = this.doPrint()
      this.resolve(results);
      this.dialog = false;
    },
    cancel() {
      this.reject('cancel');
      this.dialog = false;
    },
    enableAll() {
      this.enable = new Array(9999)
      this.enable.fill(true)
      this.enable.map(item => item = true)
      // console.log(this.enable);
    },
    changeAll(enable) {
      let enablex = this.enable.map((item) => item = enable)
      this.enable = enablex
    },
    change(x){
      (this.enable[x]) ? this.enable[x] = false : this.enable[x] = true
      console.log(this.enable[x])
    },
    getData() {
      this.axios
        .get(this.url + "/api/label?content.page.status=enable")
        .then(r => {
          if (r.data) {
            let data = this.giziObject.map(item => {
              let label = r.data.find(label => {
                return label.content.page.etiket === item.tipe
              })
              return { ...item, ...label };
            })
            this.label = data;
            //this.label = r.data.data
            // console.log(this.label)
          }
        })
        .catch(err => {
          console.log(err.message);
        });
    },
    doPrint() {
      let url = this.printUrl + "/print";
      var berkas = document.querySelectorAll(".berkas");
      let jobs = []
      for (var x = 0; x < berkas.length; x++) {
        if(document.querySelectorAll("input[name=enablex]")[x] && this.enable[document.querySelectorAll("input[name=enablex]")[x].value] == true) {
          jobs.push({
            printer: {id: document.querySelectorAll("input[name=printerId]")[x].value, name: document.querySelectorAll("input[name=selectedPrinter]")[x].value},
            settings: {
              copies: document.querySelectorAll("input[name=jumlah]")[x].value,
            },
            obat: document.querySelectorAll("input[name=obat]")[x].value,
            date: this.date
          });
        }
      }
      console.log(jobs);
      return jobs
    }
  },
  watch: {
    dialog(value) {
      if(value)
        this.$refs.printData && this.$refs.printData.changeAll(this.enable)
    }
  },
}
</script>
